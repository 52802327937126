@import "../node_modules/ag-grid-community/src/styles/ag-theme-material/sass/legacy/ag-theme-material-v22-compat";
@import "./styles/colors.modules.scss";
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');

html,body,#root{
  height: 100vh;
}

.ag-root ::-webkit-scrollbar {
  width: 0.7em;
  height: 0.7em
}

::-moz-selection { background: $lightPrimary; color: $white }
::selection { background: $lightPrimary; color: $white }

* {
  font-family: 'Quicksand', sans-serif !important;
}

.ag-icon {
  font-family: "agGridMaterial" !important;
}

.ag-react-container {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.ag-theme-material .ag-cell-inline-editing {
  border-color: $primary !important;
  border-color: var(--ag-input-focus-border-color, var(--ag-material-accent-color, $primary)) !important;
}

.ag-cell-focus {
  border: none !important;
}

.ag-react-container .ag-grid-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ag-theme-container, .ag-header-cell-label {
  color: $black !important;
}

label {
  color: $black !important;
}

label.MuiInputLabel-shrink {
  font-weight: bolder !important;
}

.Mui-disabled {
  color: $black !important
}

div[col-id="operations"] .ag-header-cell-label,
div[col-id="operations"].ag-header-cell,
div[col-id="info"].ag-header-cell,
div[col-id="info"] .ag-header-cell-label,
[col-id="operations"] .ag-react-container,
[col-id="info"] .ag-react-container {
  justify-content: center;
  text-align: center;
  color: black;
}

.MuiInputBase-root {
  line-height: unset !important;
  font-size: 16px !important;
}

#menu-appbar .MuiPaper-root.MuiMenu-paper ul.MuiMenu-list,
  #menu-appbar .MuiPaper-root.MuiMenu-paper{
  background-color: $primary !important;
}

.MuiInputBase-input {
  font-size: 16px !important;
}

.MuiInputLabel-root {
  font-size: 16px !important;
}

.MuiCardHeader-title {
  font-size: medium !important;
}

.MuiSelect-selectMenu {
  min-height: 0 !important;
}

.MuiListItemIcon-root {
  min-width: 25px !important;
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0,0,0, 0.5) !important
}

.MuiFormControl-marginDense {
  margin-top: 0 !important;
}

[col-id="operations"] .ag-cell-wrapper,
[col-id="info"] .ag-cell-wrapper, 
[col-id="content"] .ag-cell-wrapper {
  justify-content: center !important;
  text-align: center !important;
  align-items: baseline;
}

.MuiTab-root {
  transition: all 0.8s;
}

.Mui-selected.MuiTab-root {
  background-color: $darkGrey;
}